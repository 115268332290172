export function useBrand() {
  const brand = process.env.BRAND;
  const isSuperprofikCz = brand === "superprofik-cz";
  const isJoszaki = brand === "joszaki";

  return {
    country: brand,
    isSuperprofikCz,
    isJoszaki,
  };
}
