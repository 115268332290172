<template>
  <JoszakiCard
    class="BlockTender grid grid-cols-1 relative gap-2"
    :class="{
      'md:grid-cols-2': !collapsed,
    }"
    :type="invertColors ? 'default' : 'primary'"
  >
    <div class="z-19">
      <div class="text-xl font-bold">
        {{ title ?? $t("tenderBlock.title") }}
        <span
          v-if="collapsedByDefault"
          class="float-right h-7 w-7 cursor-pointer flex items-center justify-center"
          :class="{
            'md:hidden': !collapsed,
          }"
          @click="toggleCollapsed"
        >
          <IconComponent :icon="collapsed ? 'angle-down' : 'angle-up'" />
        </span>
      </div>
      <!-- eslint-disable-next-line -->
      <p v-if="!collapsed" class="text-sm whitespace-pre-wrap">
        {{ subTitle ?? $t("tenderBlock.subTitle") }}
      </p>
    </div>
    <div v-if="!collapsed" class="flex flex-col justify-between">
      <div
        v-if="collapsedByDefault"
        class="hidden md:flex self-end h-7 w-7 cursor-pointer items-center justify-center text-xl lg:text-2xl font-bold"
        @click="toggleCollapsed"
      >
        <IconComponent :icon="collapsed ? 'angle-down' : 'angle-up'" />
      </div>
      <JoszakiInputOld
        v-if="textInput && !multiLine"
        v-model="tenderText"
        icon-left="pen"
        class="mb-0 mt-0"
        :placeholder="placeholder ?? $t('tenderBlock.placeholder')"
        @focusin="$trackers.trackClick('BlockTenderInput', trackingData)"
      />
      <JoszakiTextAreaOld
        v-if="textInput && multiLine"
        v-model="tenderText"
        class="text-black"
        :placeholder="placeholder ?? $t('tenderBlock.placeholder')"
      />
      <JoszakiButton
        class="w-full"
        :class="{
          '': !textInput,
          'mt-2': textInput,
        }"
        type="info"
        inverted
        :outline="invertColors"
        @click="requestTender"
      >
        {{
          $ab.isVariant("cards-request-now-text", "test")
            ? "Ajánlatkérés most"
            : $t(`tenderBlock.requestButton`)
        }}
      </JoszakiButton>
    </div>
  </JoszakiCard>
</template>

<script>
import JoszakiInputOld from "~/components/joszaki/JoszakiInputOld.vue";
import JoszakiButton from "~/components/_refactored/common/JoszakiButton.vue";

export default {
  components: {
    JoszakiButton,
    JoszakiInputOld,
  },
  props: {
    textInput: {
      type: Boolean,
      default: false,
    },
    multiLine: {
      type: Boolean,
      default: false,
    },
    invertColors: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    collapsedByDefault: {
      type: Boolean,
      default: false,
    },
    instanceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenderText: "",
      collapsed: this.collapsedByDefault,
    };
  },
  computed: {
    trackingData() {
      if (this.instanceId) {
        return { instanceId: this.instanceId };
      }

      return {};
    },
  },
  methods: {
    requestTender() {
      this.$track.analyticsEvent({
        category: "block",
        action: "kattintas-ajanlatkeresre",
      });

      this.$trackers.trackClick("BlockTenderButton", this.trackingData);

      this.$emit("requestTender", this.tenderText);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;

      this.$trackers.trackClick("BlockTenderCollapseButton", {
        collapsed: this.collapsed,
        ...this.trackingData,
      });
    },
  },
};
</script>

<style></style>
